import LogRocket from "logrocket"
import createPlugin from "logrocket-vuex"
import { predicate } from "@/modules/logrocket/util"

const LOG_ROCKET_KEY = "ndk2s5/remotely-fun"

let initialized = false
let identified = false

export default class LogRocketService {
  static initialize(store) {
    if (initialized) return
    initialized = true
    LogRocket.init(LOG_ROCKET_KEY)
    const plugin = createPlugin(LogRocket, predicate)
    plugin(store)
  }
  static identify(user) {
    if (identified) return
    identified = true
    LogRocket.identify(user.id, {
      name: (user.firstname + " " + user.lastname).trim(),
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      gameID: user.gameID,
      clientID: user.clientID
    })
  }
}
