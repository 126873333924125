import type { Payload } from "vuex"

import { IGNORED_MUTATION_TYPES } from "./config"

export function predicate(mutation: Payload): Payload | null {
  if (IGNORED_MUTATION_TYPES.includes(mutation.type)) {
    return null
  }

  return mutation
}
